import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { Spin } from 'antd';
import Routes from '../Global/Routes';
import authStore from '../Store/authstore';
 
const PublicRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        await authStore.getCurrentUser((error, currentUser) => {
          if (error) {
            setIsAuthenticated(false); 
          } else {
            setIsAuthenticated(currentUser !== null);
          }
          setIsLoading(false);
        });
      } catch (error) {
        console.error('Error in useEffect:', error);
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };

    fetchCurrentUser();
  }, []);
 
  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (isAuthenticated) {
    return <Navigate to={Routes.debugger} />;
  }

  return children;
};

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
