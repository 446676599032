class Utility {

    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            const base = `00${c.charCodeAt(0).toString(16)}`;
            return `%${base.slice(-2)}`;
          })
          .join(""),
      );
  
      return JSON.parse(jsonPayload);
    }
  }
  
  export default new Utility();
  
   