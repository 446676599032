// src/App.js
import React, { useState,useEffect } from 'react';
import { Layout, Tabs,  Space, Spin, Button, Switch, message} from 'antd';
import { useQueryClient,useIsFetching,useQuery } from '@tanstack/react-query';
import RedisStatusTable from './components/RedisStatusTable';
import 'antd/dist/reset.css'; // Import Ant Design styles
import { clearAllRedisData, getProductionSwitchStatus, updateProductionSwitchStatus , stopAllProcessing, startAllProcessing  } from '../src/services/api';
import { Modal } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import authstore from './Store/authstore';
import Routes from './Global/Routes';


const { Content } = Layout;


const App = () => {
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState('1');
  const isFetching = useIsFetching(['redisData']);

  const [liveStatus, setLiveStatus] = useState(false);

  const navigate = useNavigate();


  const {
    data: switchStatus
  } = useQuery({
    queryKey:  ['productionSwitchStatus'],
    queryFn: async () => {
      const result = await getProductionSwitchStatus();
      return result == 'true';
    },
    
      refetchInterval: 5000, // 5 seconds
      keepPreviousData: true, // Keeps previous data while fetching new data
      staleTime: 5000, // Data considered fresh for 5 seconds
      refetchIntervalInBackground: true,
});


  useEffect(() => {
    if (switchStatus !== undefined) {
      setLiveStatus(switchStatus);
    }
  }, [switchStatus]);

  const handleLiveToggle = async (checked) => {
    setLiveStatus(checked);
    try {
      const response = await updateProductionSwitchStatus(checked);
      if (response.status === 200) {
        message.success(`Live status updated to ${checked ? 'On' : 'Off'}`);
      } else {
        setLiveStatus(!checked);
        message.error('Failed to update live status');
      }
    } catch (error) {
      setLiveStatus(!checked);
      message.error(`Error updating live status: ${error.message}`);
    }
  };

  const handleClearAll = async () => {
    try {
      const response = await clearAllRedisData();
      if (response.status === 200) {
        message.success('All Safes cleared successfully!');
        queryClient.invalidateQueries(['redisData']);
      } else {
        message.error('Failed to clear all Safes');
      }
    } catch (error) {
      message.error(`Error clearing all Safes: ${error.message}`);
    }
  };

  const handleStopAll = async () => {
    try {
      const response = await stopAllProcessing();
      if (response.status === 200) {
        message.success('All Safes stoped successfully!');
        queryClient.invalidateQueries(['redisData']);
      } else {
        message.error('Failed to stop all Safes');
      }
    } catch (error) {
      message.error(`Error stopping all Safes: ${error.message}`);
    }
  };

  const handleStartAll = async () => {
    try {
      const response = await startAllProcessing();
      if (response.status === 200) {
        message.success('All Safes started successfully!');
        queryClient.invalidateQueries(['redisData']);
      } else {
        message.error('Failed to start all Safes');
      }
    } catch (error) {
      message.error(`Error starting all Safes: ${error.message}`);
    }
  };

  const handleSignOut = async () => {
    try {
      await authstore.signOut();
      navigate(Routes.login);
    } catch (error) {
      message.error('Error during sign-out process');
    }
  };  

  const extra = activeTab === '1' ? (
    <>
    <Space style={{ marginBottom: 16, marginRight:16 }}>
    {isFetching > 0 && (
        <Spin size="small" tip="Refreshing..." />
      )}
      <span>Live:</span>
      <Switch checked={liveStatus} onChange={handleLiveToggle} checkedChildren="On" unCheckedChildren="Off" />
    </Space>
    <Space  style={{ marginRight:16 }}>
   
      <Button
        type="primary"
        danger
        onClick={() => {
          Modal.confirm({
            title: 'Are you sure you want to delete all safe info',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => handleClearAll(),
          });
        }}
      >
        Delete All
      </Button>
    </Space>

    <Space style={{ marginRight:16 }}>
   
   <Button
     type="primary"
     danger
     onClick={() => {
       Modal.confirm({
         title: 'Are you sure you want to Stop all safe processing',
         okText: 'Yes',
         cancelText: 'No',
         onOk: () => handleStopAll(),
       });
     }}
   >
     Stop All
   </Button>
 </Space>

 <Space>
   
   <Button
     type="primary"
     onClick={() => {
       Modal.confirm({
         title: 'Are you sure you want to Start all safe processing',
         okText: 'Yes',
         cancelText: 'No',
         onOk: () => handleStartAll(),
       });
     }}
   >
     Start All
   </Button>

   <Button
  type="primary"
  icon={<LogoutOutlined />}
  onClick={() => {
    Modal.confirm({
      title: 'Are you sure you want to sign out?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: handleSignOut, 
    });
  }}
/>
 </Space>

    </>
  ) : null;


  

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Content style={{ margin: '20px' }}>
          <Tabs
            defaultActiveKey="1"
            type="card"
            onChange={(key) => setActiveTab(key)}
            tabBarExtraContent={extra}
          >
            <Tabs.TabPane tab="REDIS Status" key="1">
              <RedisStatusTable />
            </Tabs.TabPane>
          </Tabs>
        </Content>
      </Layout>
    </>
  );
};
export default App;
 