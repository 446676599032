import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Spin } from 'antd';

const EmailSignInModal = ({
  visible,
  loading,
  onSignIn,
  error,
  setError,
  setEmail,
  setPassword,
  email,
  password,
  onForgotPassword
}) => {
  const handleSignIn = () => {
    if (email && password) {
      onSignIn(email, password);
    } else {
      setError('Please fill in all fields');
    }
  };

  return (
    <Modal
      title="Sign In with Email"
      open={visible}
      footer={null}
      centered
      width={350}
      closeIcon={null}
    >
      <Spin spinning={loading}>
        <Form layout="vertical">
          <Form.Item label="Email">
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
            />
          </Form.Item>
          <Form.Item label="Password">
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
          </Form.Item>
          {error && (
            <p style={{ color: '#f56565', textAlign: 'center' }}>
              {error}
            </p>
          )}
          <Button
            type="link"
            style={{ marginLeft: "170px", fontWeight: 'bold', paddingBottom: "10px" }}
            onClick={onForgotPassword}
          >
            Forgot Password?
          </Button>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.5rem',
              marginTop: '1rem'
            }}
          >
            <Button type="primary" onClick={handleSignIn} block>
              Sign In
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

EmailSignInModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired, 
  onSignIn: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onForgotPassword: PropTypes.func.isRequired, 
};

export default EmailSignInModal;
