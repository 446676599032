import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Spin } from 'antd';

const ResetPasswordModal = ({
  visible,
  onOk,
  onCancel,
  loading,
  email,
  setEmail,
  error,
}) => {

  return (
    <Modal
      title="Forgot Password"
      open={visible}
      onCancel={onCancel} 
      footer={null}
      centered 
      width={348}
    >
      <Spin spinning={loading}>
        <Form layout="vertical">
          <Form.Item label="Email">
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
            />
          </Form.Item>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', textAlign: 'center' }}>
            <Button type="primary" onClick={onOk}>Send Reset Code</Button>
            <Button onClick={onCancel}>Cancel</Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

ResetPasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default ResetPasswordModal;
