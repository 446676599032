// src/services/api.js
import axios from 'axios';
import moment from 'moment';
import authstore from '../Store/authstore';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_REDIS_API_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('aptoken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error); 
  }
);
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      authstore.refreshSession();
      return;
    }
    return Promise.reject(error);
  }
);

// Utility function to format time ago
export const formatTimeAgo = (seconds) => {
  const duration = moment.duration(seconds, 'seconds');
  if (duration.asSeconds() < 60) {
    return `${Math.floor(duration.asSeconds())} seconds ago`;
  } else if (duration.asMinutes() < 60) {
    return `${Math.floor(duration.asMinutes())} minutes ago`;
  } else if (duration.asHours() < 24) {
    return `${Math.floor(duration.asHours())} hours ago`;
  } else {
    return `${Math.floor(duration.asDays())} days ago`;
  }
};

// Fetch Redis Data Function
export const fetchRedisData = async ({
  pageSize,
  safeId = '',
  status = '',
  pendingEvents = '',
  pageIndex = 0,
} = {}) => {
  try {
  
    let queryParams = `?limit=${pageSize}&pageIndex=${pageIndex}`;
    
    if (safeId) queryParams += `&SafeId=${safeId}`;
    if (status) queryParams += `&Status=${status}`;
    if (pendingEvents) queryParams += `&pending-condition=${pendingEvents.condition}&value=${pendingEvents.value}`;

    const response = await apiClient.get(`/get_redis_data${queryParams}`);

    const data = response.data;

    const transformedData = Object.keys(data.items).map((key) => {
      const parsedItem = data.items[key];

      const safeGet = (value) => (value !== null && value !== undefined ? value : '');
      const safeGetNumber = (value) => (value !== null && value !== undefined ? value : 0 );

      const lastTx = safeGetNumber(parsedItem.LastProcessedTransaction);
      const fistTx = safeGetNumber(parsedItem.FirstProcessedTransaction);
      let  eventsProcessed = 0;
      if(lastTx > 0 && fistTx > 0){
        eventsProcessed = (lastTx - fistTx) + 1;
      }

      return {
        key: safeGet(key),
        safeId: safeGet(key),
        Status: safeGet(parsedItem.Status),
        pendingEvents: safeGet(parsedItem.pendingEvents),
        processedEvents: eventsProcessed,
        DepartmentDepositReportProcessedTime: safeGet(parsedItem.DepartmentDepositReportProcessedTime),
        CurrencyExchangeReportProcessedTime: safeGet(parsedItem.CurrencyExchangeReportProcessedTime),
        AdjustmentReportProcessedTime: safeGet(parsedItem.AdjustmentReportProcessedTime),
        EODDetailedReportProcessedTime: safeGet(parsedItem.EODDetailedReportProcessedTime),
        LastDyamoDBRecvdTime: safeGet(parsedItem.LastDyamoDBRecvdTime),
        AuditReportProcessedTime: safeGet(parsedItem.AuditReportProcessedTime),
        LastDynamoDBRecvdTransaction: safeGet(parsedItem.LastDynamoDBRecvdTransaction),
        MissedCourierReportLT: safeGet(parsedItem.MissedCourierReportLT),
        LastProcessedTime: safeGet(parsedItem.LastProcessedTime),
        MissedCourierReportProcessedTime: safeGet(parsedItem.MissedCourierReportProcessedTime),
        AuditReportLT: safeGet(parsedItem.AuditReportLT),
        UserSummaryReportProcessedTime: safeGet(parsedItem.UserSummaryReportProcessedTime),
        PostingCourierReportLT: safeGet(parsedItem.PostingCourierReportLT),
        FirstProcessedTransaction: safeGet(parsedItem.FirstProcessedTransaction),
        EODBusinessReportLT: safeGet(parsedItem.EODBusinessReportLT),
        PostingBusinessReportProcessedTime: safeGet(parsedItem.PostingBusinessReportProcessedTime),
        LastTransmissionReportLT: safeGet(parsedItem.LastTransmissionReportLT),
        LastTransmissionReportProcessedTime: safeGet(parsedItem.LastTransmissionReportProcessedTime),
        PostingCourierReportProcessedTime: safeGet(parsedItem.PostingCourierReportProcessedTime),
        DepartmentDepositReportLT: safeGet(parsedItem.DepartmentDepositReportLT),
        ChangeOrderReportProcessedTime: safeGet(parsedItem.ChangeOrderReportProcessedTime),
        EODBusinessReportProcessedTime: safeGet(parsedItem.EODBusinessReportProcessedTime),
        PostingBusinessReportLT: safeGet(parsedItem.PostingBusinessReportLT),
        LastProcessedTransaction: safeGet(parsedItem.LastProcessedTransaction),
        EODCourierReportLT: safeGet(parsedItem.EODCourierReportLT),
        AdjustmentReportLT: safeGet(parsedItem.AdjustmentReportLT),
        ChangeOrderReportLT: safeGet(parsedItem.ChangeOrderReportLT),
        EODDetailedReportLT: safeGet(parsedItem.EODDetailedReportLT),
        CurrencyExchangeReportLT: safeGet(parsedItem.CurrencyExchangeReportLT),
        EODCourierReportProcessedTime: safeGet(parsedItem.EODCourierReportProcessedTime),
        UserSummaryReportLT: safeGet(parsedItem.UserSummaryReportLT),
        ShiftReportProcessedTime:safeGet(parsedItem.ShiftReportProcessedTime),
        ShiftReportLT: safeGet(parsedItem.ShiftReportLT),
        CassettePercentageReportLT: safeGet(parsedItem.CassettePercentageReportLT),
        CassettePercentageReportProcessedTime: safeGet(parsedItem.CassettePercentageReportProcessedTime),
        CourierPickupReportLT: safeGet(parsedItem.CourierPickupReportLT),
        CourierPickupReportProcessedTime: safeGet(parsedItem.CourierPickupReportProcessedTime),
        DepartmentBusinessReportLT: safeGet(parsedItem.DepartmentBusinessReportLT),
        DepartmentBusinessReportProcessedTime: safeGet(parsedItem.DepartmentBusinessReportProcessedTime),
        DispenseDetailedReportLT: safeGet(parsedItem.DispenseDetailedReportLT),
        DispenseDetailedReportProcessedTime: safeGet(parsedItem.DispenseDetailedReportProcessedTime),
        DispenseSplitReportLT: safeGet(parsedItem.DispenseSplitReportLT),
        DispenseSplitReportProcessedTime: safeGet(parsedItem.DispenseSplitReportProcessedTime),
        DispenseSummaryReportLT: safeGet(parsedItem.DispenseSummaryReportLT),
        DispenseSummaryReportProcessedTime: safeGet(parsedItem.DispenseSummaryReportProcessedTime),
        MissingEODReportLT: safeGet(parsedItem.MissingEODReportLT),
        MissingEODReportProcessedTime: safeGet(parsedItem.MissingEODReportProcessedTime),
        TubeAdjustmentReportLT: safeGet(parsedItem.TubeAdjustmentReportLT),
        TubeAdjustmentReportProcessedTime: safeGet(parsedItem.TubeAdjustmentReportProcessedTime),
        TubeInventoryReportLT: safeGet(parsedItem.TubeInventoryReportLT),
        TubeInventoryReportProcessedTime: safeGet(parsedItem.TubeInventoryReportProcessedTime),
        UserDepartmentReportLT: safeGet(parsedItem.UserDepartmentReportLT),
        UserDepartmentReportProcessedTime: safeGet(parsedItem.UserDepartmentReportProcessedTime),
        ...(parsedItem.Error && { Error: safeGet(parsedItem.Error) })
      };
    });

    transformedData.sort((a, b) => a.safeId.localeCompare(b.safeId));
    return { data: transformedData, totalRows: data.total_rows };
  } catch (error) {
    console.error('Error fetching Redis data: ', error);
    throw new Error('Failed to fetch Redis data');
  }
};

// Similarly, define fetchLambdaErrors if using React Query for it
export const fetchLambdaErrors = async ({
  pageSize,
  safeId = '',
  pageIndex = 0,
} = {}) => {
  try {
    let queryParams = `?limit=${pageSize}&pageIndex=${pageIndex}`;

    if (safeId) queryParams += `&SafeId=${safeId}`;

    const response = await apiClient.get(`${process.env.REACT_APP_LAMBDA_ERRORS_API_URL}${queryParams}`);

    const data = response.data;

    const transformedData = data.items.map((item, index) => ({
      key: index,
      safeId: item.SafeId,
      lastError: item.LastError,
      txNumber: item.TxNumber,
      time: item.Time,
      // Add more fields if necessary
    }));

    return { data: transformedData, totalRows: data.total_rows };
  } catch (error) {
    console.error('Error fetching Lambda Errors data: ', error);
    throw new Error('Failed to fetch Lambda Errors data');
  }
};


export const clearRedisData = async (safeId) => {
  try {
   
    const response = await apiClient.get(`/clearRedisData?safe_id=${safeId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const clearAllRedisData = async () => {
  try {
     
    const response = await apiClient.get(`/clearRedisData`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const stopAllProcessing = async () => {
  try {
     
    const response = await apiClient.get(`/update-all-processing-status?status=Stopped`);
    return response;
  } catch (error) {
    throw error;
  }
};


export const startAllProcessing = async () => {
  try {
     
    const response = await apiClient.get(`/update-all-processing-status?status=Done`);
    return response;
  } catch (error) {
    throw error;
  }
};



export const updateStatus = async (record) => {
  try {
    const response = await apiClient.get(`/update-redis-status?safe_id=${record.safeId}`);
    return response;
  } catch (error) {
    throw error;
  }

};

export const getProductionSwitchStatus = async () => {
  try {
    const response = await apiClient.get(`/v2status`);
    return response.data.v2_status;
  } catch (error) {
    throw error;
  }

};

export const updateProductionSwitchStatus = async (status) => {
  try {
    const response = await apiClient.post(`/v2status?v2_status=${status}`);
    return response;
  } catch (error) {
    throw error;
  }

};