// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Router from './Routing';
import QueryClientProvider from './QueryClientProvider';

// Create a client
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider>
        <Router />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
); 