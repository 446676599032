import React from 'react';

const ErrorDescription = ({ errorDetails = null }) => {
  // Check if errorDetails exist
  if (!errorDetails) {
    return <p>No error details available.</p>;
  }

  // Parse the "Cause" if it's a JSON string
  let parsedCause = null;
  if (errorDetails.Cause) {
    try {
      parsedCause = JSON.parse(errorDetails.Cause);
    } catch (e) {
      console.error("Error parsing the Cause field:", e);
    }
  }

  return (
    <div style={{ padding: '16px' }}>
      <p><strong>Transaction No:</strong> {errorDetails.TransactionNo}</p>
      <p><strong>State Machine Unique Name:</strong> {errorDetails.StateMachineUniqueName}</p>
      <p><strong>Error Type:</strong> {errorDetails.Type}</p>

      {parsedCause ? (
        <>
          <p><strong>Error Message:</strong> {parsedCause.errorMessage}</p>
          <p><strong>Error Type:</strong> {parsedCause.errorType}</p>
          <p><strong>Request ID:</strong> {parsedCause.requestId}</p>
          <p><strong>Stack Trace:</strong></p>
          {parsedCause.stackTrace && parsedCause.stackTrace.length > 0 ? (
            <ul>
              {parsedCause.stackTrace.map((trace, index) => (
                <li key={index}>{trace}</li>
              ))}
            </ul>
          ) : (
            <p>No stack trace available</p>
          )}
        </>
      ) : (
        <p>No cause details available</p>
      )}

      <p><strong>Error Occurred Time:</strong> {new Date(errorDetails.ErrorOccuredTime * 1000).toLocaleString()}</p>
    </div>
  );
};

export default ErrorDescription;
