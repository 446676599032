import React, { useState } from 'react';
import EmailSignInModal from './LoginModal/EmailModal';
import NewPasswordModal from './LoginModal/NewPassword';
import ResetPasswordModal from './LoginModal/ResetPassword';
import ConfirmResetPasswordModal from './LoginModal/ConfirmResetPassword';
import AuthStore from '../../Store/authstore';
import { useNavigate } from 'react-router-dom';
import Routes from '../../Global/Routes';

const Login = () => {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [isNewPasswordModalVisible, setIsNewPasswordModalVisible] = useState(false);
    const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
    const [isConfirmResetPasswordModalOpen, setIsConfirmResetPasswordModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [resetCode, setResetCode] = useState('');

    const navigate = useNavigate();

    const handleSignIn = (email, password) => {
        setLoading(true);
        AuthStore.signIn(email, password, (error) => {
            setLoading(false);
            if (error) {
                if (error === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
                    setIsModalVisible(false);
                    setError('')
                    setIsNewPasswordModalVisible(true);
                } else {
                    setError(error);
                }
            } else {
                navigate(Routes.debugger);
            }
        });
    };

    const handleNewPasswordSubmit = (newPassword) => {
        setLoading(true);
        AuthStore.confirmSignIn(email, newPassword, (error) => {
            setLoading(false);
            if (error) {
                setError(error);
            } else {
                navigate(Routes.debugger);
            }
        });
    };

    const handleForgotPassword = () => {
        setError("");
        setEmail("");
        setIsModalVisible(false);
        setIsResetPasswordModalOpen(true);
    };

    const handleResetPassword = () => {
        setLoading(true);
        AuthStore.resetPassword(email, (error) => {
            setLoading(false);
            if (error) {
                setError(error);
            } else {
                setError("");
                setIsResetPasswordModalOpen(false);
                setIsConfirmResetPasswordModalOpen(true);
            }
        });
    };

    const handleConfirmResetPassword = () => {
        setLoading(true);
        AuthStore.confirmResetPassword(email, resetCode, password, (error) => {
            setLoading(false);
            if (error) {
                setError(error);
            } else {
                setIsConfirmResetPasswordModalOpen(false);
                setEmail('');
                setPassword('');
                setError('');
                setResetCode('');
                setConfirmPassword('');
                setIsModalVisible(true);
            }
        });
    };

    const handleCancel = () => {
        setIsModalVisible(true);
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setError("");
        setIsConfirmResetPasswordModalOpen(false);
        setIsResetPasswordModalOpen(false);
        setResetCode('');
    };

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: '#f3f4f6'
        }}>
            <EmailSignInModal
                visible={isModalVisible}
                loading={loading}
                onSignIn={handleSignIn}
                error={error}
                setError={setError}
                setEmail={setEmail}
                setPassword={setPassword}
                email={email}
                password={password}
                onForgotPassword={handleForgotPassword}
            />

            <NewPasswordModal
                visible={isNewPasswordModalVisible}
                loading={loading}
                onSubmit={handleNewPasswordSubmit}
                error={error}
                setError={setError} 
                setNewPassword={setNewPassword}
                newPassword={newPassword}
            />

            <ResetPasswordModal
                visible={isResetPasswordModalOpen}
                onOk={handleResetPassword}
                onCancel={handleCancel}
                loading={loading}
                email={email}
                setEmail={setEmail}
                error={error}
            />

            <ConfirmResetPasswordModal
                visible={isConfirmResetPasswordModalOpen}
                onOk={handleConfirmResetPassword}
                onCancel={handleCancel}
                loading={loading}
                code={resetCode}
                setCode={setResetCode}
                newPassword={password}
                setNewPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                error={error}
                setError={setError}
            />
        </div>
    );
};

export default Login;
