import React, { Suspense } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import { Skeleton } from "antd";
import Routes from "../Global/Routes";
import Login from "../Pages/Login/login";
import App from "../App";
import AccessRoute from "./privateRoute";
import PublicRoute from "./publicRoute";

const Router = () => (
    <Suspense fallback={<Skeleton />}>
        <Switch>
            <Route
                caseSensitive
                path={Routes.login}
                element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                }
            />
            <Route
                caseSensitive
                path={Routes.debugger} 
                element={
                    <AccessRoute>
                        <App />
                    </AccessRoute>
                }
            />
        </Switch>
    </Suspense> 
);

export default Router;