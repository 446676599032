import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Spin } from 'antd';

const ConfirmResetPasswordModal = ({
  visible,
  onOk,
  onCancel,
  loading, 
  code,
  setCode,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  error,
  setError
}) => {
 
  const handleConfirmReset = () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    onOk();
  };

  return (
    <Modal
      title="Confirm Password Reset"
      open={visible}
      onCancel={onCancel}
      footer={null}
      centered
      width={348}
    >
      <Spin spinning={loading}>
        <Form layout="vertical">
          <Form.Item label="Reset Code">
            <Input
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter the code sent to your email"
            />
          </Form.Item>
          <Form.Item label="New Password">
            <Input.Password
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter your new password"
            />
          </Form.Item>
          <Form.Item label="Confirm Password">
            <Input.Password
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your new password"
            />
          </Form.Item>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', textAlign: 'center' }}>
            <Button type="primary" onClick={handleConfirmReset}>Reset Password</Button>
            <Button onClick={onCancel}>Cancel</Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

ConfirmResetPasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  code: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
  newPassword: PropTypes.string.isRequired,
  setNewPassword: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
};

export default ConfirmResetPasswordModal;
