// src/QueryClientProvider.js
import React from 'react';
import { QueryClient, QueryClientProvider as ReactQueryProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const QueryClientProvider = ({ children }) => {
  return <ReactQueryProvider client={queryClient}>{children}</ReactQueryProvider>;
};

export default QueryClientProvider;
