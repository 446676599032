// src/components/RedisStatusTable.js
import React, { useState, useEffect } from 'react';
import { Table, Input, Select, Space, Button } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { fetchRedisData,clearRedisData, updateStatus} from '../services/api';
import { message } from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { DeleteOutlined, LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined,AlertOutlined } from '@ant-design/icons';
import DescriptionComponent from './DescriptionComponent';
import ErrorDescription from './ErrorDescription';

const { Option } = Select;

const pageSize = process.env.REACT_APP_PAGE_SIZE || 5;

const RedisStatusTable = () => {
  // Filters
  const [safeIdFilter, setSafeIdFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [pendingEventsFilter, setPendingEventsFilter] = useState(null);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedRow, setSelectedRow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [selectedErrorDetails, setSelectedErrorDetails] = useState(null);

  const handleRowClick = (record) => {
    setSelectedRow(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
  };

  const handleErrorButtonClick = (errorDetails) => {
    setSelectedErrorDetails(errorDetails);
    setIsErrorModalVisible(true);
  };

  const handleErrorModalClose = () => {
    setIsErrorModalVisible(false);
    setSelectedErrorDetails(null);
  };

  // Define the query key based on filters and pagination
  const queryKey = ['redisData', { safeIdFilter, statusFilter, pendingEventsFilter, currentPage , pageSize}];

  // Use React Query's useQuery hook
  const {
    data: queryData,
    isLoading,
    isError,
    error,
    refetch
  } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {

      const result = await fetchRedisData({
        pageSize,
        safeId: safeIdFilter,
        status: statusFilter,
        pendingEvents: pendingEventsFilter,
        pageIndex: currentPage - 1,
      })
      return result;
    },
    
      refetchInterval: 5000, // 5 seconds
      keepPreviousData: true, // Keeps previous data while fetching new data
      staleTime: 5000, // Data considered fresh for 5 seconds
      refetchIntervalInBackground: true,
    
});


  const { data = [], totalRows = 0 } = queryData || {};

  function formatTimeFromNow(unixTimestamp) {
    const timestamp = moment.unix(unixTimestamp);
    const diffInSeconds = moment().diff(timestamp, 'seconds');
  
    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else {
      return timestamp.fromNow();
    }
  }

  useEffect(() => {
    if (selectedRow) {
      const updatedRecord = data.find(item => item.key === selectedRow.key);
      if (updatedRecord) {
        setSelectedRow(updatedRecord);
      }
    }
  }, [data]);

 

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    align: 'center',
    render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
  },
  {
    title: 'SafeId',
    dataIndex: 'safeId',
    key: 'safeId',
    fixed: 'left',
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Search SafeId"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <a onClick={() => confirm()}>Search</a>
          <a onClick={() => {
            clearFilters();
            confirm();
          }}>Reset</a>
        </Space>
      </div>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'Status',
    key: 'Status',
    fixed: 'left',
    filterMultiple: false, 
    filters: [
      { text: 'Done', value: 'Done' },
      { text: 'Processing', value: 'Processing' },
      { text: 'Stopped', value: 'Stopped' },
    ],
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'Pending',
    dataIndex: 'pendingEvents',
    key: 'pendingEvents',
    align: 'center',
    fixed: 'left', // Freeze this column
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: 120, marginBottom: 8 }}
          placeholder="Condition"
          onChange={(value) => {
            setSelectedKeys([{ ...selectedKeys[0], condition: value }]);
          }}
        >
          <Option value="greater">Greater than</Option>
          <Option value="less">Less than</Option>
          <Option value="equal">Equal to</Option>
          <Option value="empty">Empty</Option>
        </Select>
        <Input
          placeholder="Value"
          type="number"
          disabled={selectedKeys[0]?.condition === 'empty'}
          value={selectedKeys[0]?.value}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedKeys([{ ...selectedKeys[0], value }]);
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
  },
  {
    title: 'Processed',
    dataIndex: 'processedEvents',
    key: 'processedEvents',
    align: 'center',
  },
  {
    title: 'Last DDB Recv Time',
    dataIndex: 'LastDyamoDBRecvdTime',
    key: 'LastDyamoDBRecvdTime',
    fixed: 'left', // Freeze this column
    render: (text) => text ? formatTimeFromNow(text) : '',
  },
  {
    title: 'Last Proc Time',
    dataIndex: 'LastProcessedTime',
    key: 'LastProcessedTime',
    fixed: 'left', // Freeze this column
    render: (text) => text ? formatTimeFromNow(text) : '',
  },
  {
    title: 'Last DDB Recv Txn',
    dataIndex: 'LastDynamoDBRecvdTransaction',
    key: 'LastDynamoDBRecvdTransaction',
    fixed: 'left', // Freeze this column
  },
  {
    title: 'Last Proc Txn',
    dataIndex: 'LastProcessedTransaction',
    key: 'LastProcessedTransaction',
    fixed: 'left', // Freeze this column
  },
  {
    title: 'First Proc Txn',
    dataIndex: 'FirstProcessedTransaction',
    key: 'FirstProcessedTransaction',
  },
  {
    title: 'Action',
    key: 'action',
    fixed: 'right',
    render: (_, record) => {
      
      let parsedError = null;
      if (record.Error) {
        try {
          parsedError = JSON.parse(record.Error);
        } catch (e) {
          console.error("Error parsing the Error field:", e);
        }
      }

      const errorOccurredTime = parsedError ? parsedError.ErrorOccuredTime : null;

      // Check if the error occurred within the last 24 hours
      const isRecentError = errorOccurredTime && moment.unix(errorOccurredTime).isAfter(moment().subtract(24, 'hours'));

      return (
      
      <div style={{ display: 'flex', gap: '10px' }}>
        {/* Delete Button */}
        <Button
          icon={<DeleteOutlined />}
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            Modal.confirm({
              title: 'Are you sure you want to delete this item?',
              okText: 'Yes',
              cancelText: 'No',
              onOk: () => handleClearIndividual(record.safeId),
            });
          }}
        />

        {/* Status Button */}
        <Button
          icon={
            record.Status === "Processing" ? (
              <LoadingOutlined spin />
            ) : record.Status === "Done" ? (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
              <CloseCircleOutlined style={{ color: 'red' }} />
            )
          }
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            const newStatus = getNextStatus(record.Status);
            const currentStatus = record.Status ? record.Status : "<< no status >>"; 
            Modal.confirm({
              title: `Are you sure you want to change the status from ${currentStatus} to ${newStatus}?`,
              okText: 'Yes',
              cancelText: 'No',
              onOk: () => handleStatusChange(record),
            });
          }}
        />

    {isRecentError && (
        <Button
          icon={<AlertOutlined style={{ color: 'red' }} />}
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            handleErrorButtonClick(parsedError);
          }}
        />
      )}
  

      </div>
    )},
  },
];


  const handleClearIndividual = async (safeId) => {
    try {
      
      const response = await clearRedisData(safeId);
      if (response.status === 200) {
        message.success(`Safe ID ${safeId} cleared successfully!`);
        refetch();
      } else {
        message.error(`Failed to clear Safe ID ${safeId}`);
      }
    } catch (error) {
      message.error(`Error clearing Safe ID ${safeId}: ${error.message}`);
    }
  };

  const getNextStatus = (currentStatus) => {
    if (currentStatus === "Processing") {
      return "Stopped";
    } else if (currentStatus === "Stopped") {
      return "Processing";
    } else if (currentStatus === "Done") {
      return "Stopped";
    } else {
      return "Done";
    }
    return currentStatus;
  };
  
  const handleStatusChange = async (record) => {
    try {
      const response = await updateStatus(record);
      if (response.status === 200) {
        message.success(`Safe ID ${record.safeId} status updated successfully!`);
        refetch();
      } else {
        message.error(`Failed to update status Safe ID ${record.safeId}`);
      }
    } catch (error) {
      message.error(`Error updating status of Safe ID ${record.safeId}: ${error.message}`);
    }
  };
  

  const handleTableChange = (pagination, filters, sorter) => {

    if(pagination.current === undefined){
      setCurrentPage(1);
    } else if (pagination.current !== currentPage) {
      setCurrentPage(pagination.current);
    }

    if (filters.Status) {
      setStatusFilter(filters.Status[0]); // Update the status filter state if a specific status is selected
    } else {
      setStatusFilter(''); // Reset the status filter if "All" is selected
    }

    if (filters.safeId && filters.safeId.length > 0) {
      setSafeIdFilter(filters.safeId[0]); // Update the safeId filter state with the selected value
    } else {
      setSafeIdFilter(''); // Reset the safeId filter if no filter is applied
    }

    if (filters.pendingEvents && filters.pendingEvents.length > 0) {
      const { condition, value } = filters.pendingEvents[0];
      if (condition) {
        setPendingEventsFilter({ condition, value });
      } else {
        setPendingEventsFilter(null);
      }
    } else {
      setPendingEventsFilter(null);
    }

    refetch();
  };

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
<>

    <Table
    columns={columns}
    dataSource={data}
    loading={isLoading}
    pagination={
      totalRows > pageSize
        ? {
            current: currentPage,
            pageSize: pageSize,
            total: totalRows,
            showSizeChanger: false,
          }
        : false // Hide pagination if there is only one page
    }
    onChange={handleTableChange}
    rowKey="key"
    scroll={{ x: 'max-content' }}
    onRow={(record) => ({
      onClick: () => handleRowClick(record),
    })}
  />
   {selectedRow && (
        <Modal
          title=""
          width={1000}
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={[
          ]}
        >
          <DescriptionComponent
            record={selectedRow}
          />
        </Modal>
      )}

    {selectedErrorDetails && (
        <Modal
          title="Error Details"
          visible={isErrorModalVisible}
          onCancel={handleErrorModalClose}
          footer={[]}
        >
          <ErrorDescription errorDetails={selectedErrorDetails} />
        </Modal>
      )}
  </>
  );
};


export default RedisStatusTable;
