import React from 'react';
import { Table } from 'antd';
import moment from 'moment';

const DescriptionComponent = ({ record = {} }) => {
  // Function to format the time as per your requirement
  function formatTimeFromNow(unixTimestamp) {
    if (!unixTimestamp) {
      return '-';
    }

    const timestamp = moment.unix(unixTimestamp);
    const diffInSeconds = moment().diff(timestamp, 'seconds');

    if (diffInSeconds < 60) {
      return `${diffInSeconds} seconds ago`;
    } else {
      return timestamp.fromNow();
    }
  }

  // Prepare data for the table, ensuring that undefined record fields are handled
  const reportData = [
    {
      key: '1',
      index: 1,
      reportName: 'Audit Report',
      lastTransaction: record.AuditReportLT || '-',
      processedTime: formatTimeFromNow(record.AuditReportProcessedTime),
    },
    {
      key: '2',
      index: 2,
      reportName: 'Change Order Exception Report',
      lastTransaction: record.ChangeOrderReportLT || '-',
      processedTime: formatTimeFromNow(record.ChangeOrderReportProcessedTime),
    },
    {
      key: '3',
      index: 3,
      reportName: 'Cassette Percentage Report',
      lastTransaction: record.CassettePercentageReportLT || '-',
      processedTime: formatTimeFromNow(record.CassettePercentageReportProcessedTime),
    },
    {
      key: '4',
      index: 4,
      reportName: 'Courier Pickup Report',
      lastTransaction: record.CourierPickupReportLT || '-',
      processedTime: formatTimeFromNow(record.CourierPickupReportProcessedTime),
    },
    {
      key: '5',
      index: 5,
      reportName: 'Currency Exchange Report',
      lastTransaction: record.CurrencyExchangeReportLT || '-',
      processedTime: formatTimeFromNow(record.CurrencyExchangeReportProcessedTime),
    },
    {
      key: '6',
      index: 6,
      reportName: 'Department Deposit Report',
      lastTransaction: record.DepartmentDepositReportLT || '-',
      processedTime: formatTimeFromNow(record.DepartmentDepositReportProcessedTime),
    },
    {
      key: '7',
      index: 7,
      reportName: 'User Department Report',
      lastTransaction: record.UserDepartmentReportLT || '-',
      processedTime: formatTimeFromNow(record.UserDepartmentReportProcessedTime),
    },
    {
      key: '8',
      index: 8,
      reportName: 'Dispense Detailed Report',
      lastTransaction: record.DispenseDetailedReportLT || '-',
      processedTime: formatTimeFromNow(record.DispenseDetailedReportProcessedTime),
    },
    {
      key: '9',
      index: 9,
      reportName: 'Dispense Split Report',
      lastTransaction: record.DispenseSplitReportLT || '-',
      processedTime: formatTimeFromNow(record.DispenseSplitReportProcessedTime),
    },
    {
      key: '10',
      index: 10,
      reportName: 'Dispense Summary Report',
      lastTransaction: record.DispenseSummaryReportLT || '-',
      processedTime: formatTimeFromNow(record.DispenseSummaryReportProcessedTime),
    },
    {
      key: '11',
      index: 11,
      reportName: 'EOD Business Report',
      lastTransaction: record.EODBusinessReportLT || '-',
      processedTime: formatTimeFromNow(record.EODBusinessReportProcessedTime),
    },
    {
      key: '12',
      index: 12,
      reportName: 'EOD Courier Report',
      lastTransaction: record.EODCourierReportLT || '-',
      processedTime: formatTimeFromNow(record.EODCourierReportProcessedTime),
    },
    {
      key: '13',
      index: 13,
      reportName: 'EOD Detailed Report',
      lastTransaction: record.EODDetailedReportLT || '-',
      processedTime: formatTimeFromNow(record.EODDetailedReportProcessedTime),
    },
    {
      key: '14',
      index: 14,
      reportName: 'Last Transmission Report',
      lastTransaction: record.LastTransmissionReportLT || '-',
      processedTime: formatTimeFromNow(record.LastTransmissionReportProcessedTime),
    },
    {
      key: '15',
      index: 15,
      reportName: 'Missed Courier Report',
      lastTransaction: record.MissedCourierReportLT || '-',
      processedTime: formatTimeFromNow(record.MissedCourierReportProcessedTime),
    },
    {
      key: '16',
      index: 16,
      reportName: 'Missing EOD Report',
      lastTransaction: record.MissingEODReportLT || '-',
      processedTime: formatTimeFromNow(record.MissingEODReportProcessedTime),
    },
    {
      key: '17',
      index: 17,
      reportName: 'Posting Business Day Report',
      lastTransaction: record.PostingBusinessReportLT || '-',
      processedTime: formatTimeFromNow(record.PostingBusinessReportProcessedTime),
    },
    {
      key: '18',
      index: 18,
      reportName: 'Posting Courier Report',
      lastTransaction: record.PostingCourierReportLT || '-',
      processedTime: formatTimeFromNow(record.PostingCourierReportProcessedTime),
    },
    {
      key: '19',
      index: 19,
      reportName: 'Shift Report',
      lastTransaction: record.ShiftReportLT || '-',
      processedTime: formatTimeFromNow(record.ShiftReportProcessedTime),
    },
    {
      key: '20',
      index: 20,
      reportName: 'Tube Adjustment Report',
      lastTransaction: record.TubeAdjustmentReportLT || '-',
      processedTime: formatTimeFromNow(record.TubeAdjustmentReportProcessedTime),
    },
    {
      key: '21',
      index: 21,
      reportName: 'Tube Inventory Report',
      lastTransaction: record.TubeInventoryReportLT || '-',
      processedTime: formatTimeFromNow(record.TubeInventoryReportProcessedTime),
    },
    {
      key: '22',
      index: 22,
      reportName: 'User Report Summary',
      lastTransaction: record.UserSummaryReportLT || '-',
      processedTime: formatTimeFromNow(record.UserSummaryReportProcessedTime),
    },
    // Move "Last Transaction Number" row to the bottom
    {
      key: '23',
      index: 23,
      reportName: 'Last Transaction Number',
      lastTransaction: record.LastProcessedTransaction || '-',
      processedTime: formatTimeFromNow(record.LastProcessedTime),
    },
  ];
  

  // Get the Last Processed Transaction value
  const safeLastTransaction = record.LastProcessedTransaction || '-';

  // Define columns for the table
  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      align: 'center', // Center alignment for the index column
      width: 100, // Set width to reduce the column size
    },
    {
      title: 'Report Name',
      dataIndex: 'reportName',
      key: 'reportName',
    },
    {
      title: 'Last Processed Transaction Number',
      dataIndex: 'lastTransaction',
      key: 'lastTransaction',
      render: (text, record) => (
        <span
          style={{
            color:
              safeLastTransaction !== '-' && text !== safeLastTransaction && text !== '-' && record.reportName !== 'Last Transaction Number'
                ? 'red'
                : 'inherit',
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Last Processed Time',
      dataIndex: 'processedTime',
      key: 'processedTime',
    },
  ];

  return (
    <div style={{ maxHeight: '600px', overflowY: 'auto', padding: '16px' }}>
      <Table
        columns={columns}
        dataSource={reportData}
        pagination={false}
        bordered
        rowKey="key"
        scroll={{ y: 500 }} // Vertical scrolling enabled
      />
    </div>
  );
};

export default DescriptionComponent;
