import { Amplify } from "aws-amplify";
import {
  signIn,
  signOut,
  getCurrentUser,
  resetPassword,
  confirmResetPassword,
  fetchAuthSession,
  confirmSignIn,
} from 'aws-amplify/auth';
import awsconfig from "../aws-export";
import Routes from "../Global/Routes";
import Utility from "../Global/Utility";

Amplify.configure(awsconfig);

class AuthStore {
  email = "";
  password = "";
  error = "";
  currentUser = null;

  handleUserNameChange(email) {
    this.email = email.trim();
  }

  async signIn(email, password, callback) {
    try {
      const user = await signIn({ username: email, password });
      if (!user.isSignedIn) {
        if (user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          callback(user.nextStep.signInStep);
          return;
        }
      }
      await this.refreshSession();
      callback();
    } catch (error) {
      callback(error.message.replace("username", "email"));
    }
  }

  async confirmSignIn(email, password, callback) {
    try {
      await confirmSignIn({ username: email, challengeResponse: password });
      await this.refreshSession();
      callback();
    } catch (error) {
      callback(error.message);
    }
  }

  async signOut() {
    try {
      await signOut();
      this.currentUser = null;
      localStorage.removeItem("aptoken");
      window.location.href = Routes.login;
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  async refreshSession() {
    try {
      const session = await fetchAuthSession({ forceRefresh: true });
      const jwtToken = session?.tokens?.idToken?.toString();

      if (jwtToken) {
        localStorage.setItem("aptoken", jwtToken);
        return true;
      } else {
        throw new Error('JWT token is undefined');
      }
    } catch (error) {
      console.error('Error refreshing session:', error);
      await this.signOut();
      return false;
    }
  }

  async getCurrentUser(callback) {
    try {
      const token = localStorage.getItem("aptoken");
      if (!token) {
        throw new Error('Token not found');
      }

      const isValidSession = await this.isSessionValid(token);
      if (!isValidSession) {
        return;
      }

      const user = await getCurrentUser();
      this.currentUser = {
        user: user.username,
        userId: user.userId
      };
      callback(undefined, this.currentUser);
    } catch (error) {
      callback(error.message);
    }
  }

  async isSessionValid(token) {
    const decoded = Utility.parseJwt(token);
    const currentTime = Math.ceil(new Date().getTime() / 1000);
    const currentExpiry = Math.ceil(new Date(decoded.exp).getTime());
    const fiveMinutes = 300;
    const logUserOut = currentExpiry - fiveMinutes < currentTime;

    if (logUserOut) {
      const refreshSuccessful = await this.refreshSession();
      return refreshSuccessful;
    }
    return true;
  }

  async resetPassword(email, callback) {
    try {
      await resetPassword({ username: email });
      callback();
    } catch (error) {
      callback(error.message.replace("username", "email"));
    }
  }

  async confirmResetPassword(email, code, newPassword, callback) {
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: newPassword,
      });
      callback();
    } catch (error) {
      callback(error.message);
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthStore();
