const awsconfig = {
    Auth: {
        Cognito: {
            region: process.env.REACT_APP_REGION,
            userPoolId: process.env.REACT_APP_USERPOOLID,
            userPoolClientId: process.env.REACT_APP_USERCLIENTID,
        },
    },
};

export default awsconfig;
 