import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Spin } from 'antd';

const NewPasswordModal = ({
  visible,
  loading,
  onSubmit,
  error,
  setError,
  setNewPassword,
  newPassword,
}) => {
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return; 
    } 
    if (newPassword) {
      onSubmit(newPassword);
    } else {
      setError('Please fill in all fields');
    }
  };

  return (
    <Modal
      title="Set New Password"
      open={visible}
      footer={null}
      centered
      width={350}
      closeIcon={null}
    >
      <Spin spinning={loading}>
        <Form layout="vertical">
          <Form.Item label="New Password">
            <Input.Password
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter your new password"
            />
          </Form.Item>
          <Form.Item label="Confirm New Password">
            <Input.Password
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your new password"
            />
          </Form.Item>
          {error && (
            <p style={{ color: '#f56565', textAlign: 'center' }}>
              {error}
            </p>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.5rem',
              marginTop: '1rem'
            }}
          >
            <Button type="primary" onClick={handleSubmit}>Submit</Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

NewPasswordModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
  setNewPassword: PropTypes.func.isRequired,
  newPassword: PropTypes.string.isRequired,
};

export default NewPasswordModal;
